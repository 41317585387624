import './App.css';
import stanford from './Assets/stan.jpg';


function App() {
  return (
    <div className="App">
        <img src={stanford} className="App-logo" alt="logo" />
       
    </div>
  );
}

export default App;
